/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { useSelector , useDispatch } from 'react-redux'
import SEO from '../../../components/SEO'
import AudioPlayer from '../../../components/AudioPlayer'
import Button from '../../../components/Button'
import { FilterButton } from '../../../components/ActionBtn'
import VideoPlayer from '../../../components/VideoPlayer'
import { setPlaybackRate } from '../../../redux/videoAction'
import VideoActionButton from './../../../components/VideoActionButton'


import {
  noIcon,
  noIconSelected,
  pixel,
  pixelSelected,
  glitch,
  glitchSelected,
  bw,
  bwSelected,
  kaleidoscope,
  kaleidoscopeSelected,
  dreamy,
  dreamySelected,
  normalSpeed,
  normalSpeedSelected,
  doubleSpeed,
  doubleSpeedSelected,
  speed15,
  speed15Selected,
  speed05,
  speed05Selected
} from '../../../util/svgs'

export default function SinglePoem({ data: { poem } }) {
  const [video, setVideo] = useState({
    id: poem.originalID,
    currentTime: 0,
    muted: true
  })
  const [screenHeight, setScreenHeight] = useState(0);
  const [filterOption, setFilterOption] = useState('filter')
  const dispatch = useDispatch()
  const mainRef = useRef();
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    mainRef.current.style.height = `${vh * 100}px`;
    setScreenHeight(vh)
  }, [screenHeight])
  // video
  const cover = getSrc(poem.cover.asset)

  const {player} = useSelector((state) => state.video)

  const videoJsOptions = {
    autoplay: true,
    loop: true,
    controls: false,
    poster: cover,
    muted: video.muted,
    playsinline: true,
    videoId: video.id,
    currentTime: video.currentTime,
    videoType: 'remix',
    html5: {
      nativeTextTracks: false,
    },
    sources: [
      {
        src: `https://videodelivery.net/${video.id}/manifest/video.m3u8`,
      },
    ],
  }

  // filters
  const filterAction = (id) => {
    const currentTime = Math.round(player.currentTime())
    setVideo({
      id,
      currentTime,
      muted: false
    })
  }

  // speed (playbackrate)
  const speedAction = (id) => {
    const playbackRate = Number(id)
    player.playbackRate(playbackRate)
    dispatch(setPlaybackRate(playbackRate))
  }

  return (
    <main className="max-w-md mx-auto main relative" ref={mainRef}>
      <SEO title={poem.title} />
      <VideoPlayer {...videoJsOptions} />

      <Link
        to="/dikt"
        className="bg-brand text-black px-2 text-xl ml-auto line-through cursor-pointer focus:outline-none absolute top-4 right-4 z-30"
        replace
      >
        lukk
      </Link>
      <div className="fixed bottom-4 left-0 w-full">
        <div className="max-w-md mx-auto">
          <div className="px-4 min-w-full">
            <div className="flex mb-7">
              
              <VideoActionButton videoType="remix" />
                <div className="w-90 overflow-auto border-l-4 border-white pl-4 ml-2">
                  <div className="flex">
                    <div className={filterOption === 'filter' ? 'flex' : 'hidden'}>
                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons active"
                        type="button"
                        onClick={() => filterAction(poem.originalID)}
                      >
                        <FilterButton
                          normal={noIcon}
                          selected={noIconSelected}
                        />
                      </button>

                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => filterAction(poem.pixel)}
                      >
                        <FilterButton normal={pixel} selected={pixelSelected} />
                      </button>

                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => filterAction(poem.glitch)}
                      >
                        <FilterButton normal={glitch} selected={glitchSelected} />
                      </button>

                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => filterAction(poem.blackwhite)}
                      >
                        <FilterButton normal={bw} selected={bwSelected} />
                      </button>

                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => filterAction(poem.kaleidoskop)}
                      >
                        <FilterButton
                          normal={kaleidoscope}
                          selected={kaleidoscopeSelected}
                        />
                      </button>

                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => filterAction(poem.dreamy)}
                      >
                        <FilterButton normal={dreamy} selected={dreamySelected} />
                      </button>
                    </div>

                    <div className={filterOption === 'sound' ? 'block' : 'hidden'}>
                      <AudioPlayer />
                    </div>

                    <div className={filterOption === 'speed' ? 'flex' : 'hidden'}>
                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons active"
                        type="button"
                        onClick={() => speedAction('1')}
                      >
                        <FilterButton
                          normal={normalSpeed}
                          selected={normalSpeedSelected}
                        />
                      </button>
                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => speedAction('0.5')}
                      >
                        <FilterButton normal={speed05} selected={speed05Selected} />
                      </button>
                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => speedAction('1.5')}
                      >
                        <FilterButton normal={speed15} selected={speed15Selected} />
                      </button>
                      <button
                        className="bg-none border-0 focus:outline-none w-13 filter-buttons"
                        type="button"
                        onClick={() => speedAction('2')}
                      >
                        <FilterButton
                          normal={doubleSpeed}
                          selected={doubleSpeedSelected}
                        />
                      </button>
                    </div>
                      </div>
                  </div>
            </div>

            <div className="flex justify-between">
              <button
                type="button"
                className="btn-filter active cursor-pointer"
                onClick={() => setFilterOption('filter')}
              >
                <Button name="FILTER" />
              </button>
              <button
                type="button"
                className="btn-filter mx-4"
                onClick={() => setFilterOption('sound')}
              >
                <Button name="LYD" />
              </button>
              <button
                type="button"
                className="btn-filter"
                onClick={() => setFilterOption('speed')}
              >
                <Button name="HASTIGHET" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export const query = graphql`
  query ($id: String) {
    poem: sanityDikt(id: { eq: $id }) {
      title
      cover {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      originalID
      pixel
      dreamy
      glitch
      kaleidoskop
      blackwhite
    }
  }
`
