import React from 'react'
import ReactHowler from 'react-howler'
import store from '../redux/store'

// audio files
import NatureAudio from '../assets/audio/nature.mp3'
import RainAudio from '../assets/audio/rain.mp3'
import RadioAudio from '../assets/audio/radio.mp3'
import GlitchAudio from '../assets/audio/glitch.mp3'
import BassAudio from '../assets/audio/bass.mp3'
import MetalAudio from "../assets/audio/metal.mp3"
import PsychoAudio from "../assets/audio/psycho.mp3"
import DrumsAudio from '../assets/audio/drums.mp3'
import Jazz1Audio from '../assets/audio/jazz.mp3'
import BanglaAudio from '../assets/audio/bangla.mp3'
import HipHop1Audio from '../assets/audio/hip_hop.mp3'
import HipHop2Audio from '../assets/audio/hip_hop2.mp3'
import MiddleEastAudio from '../assets/audio/middle-east.mp3'
import ClassicAudio from '../assets/audio/classic.mp3'


import { filterButtons } from '../util'
import { FilterButton } from './ActionBtn'
import {
  noIcon,
  noIconSelected,
  classic,
  classicSelected,
  drums,
  drumsSelected,
  glitchSound,
  glitchSoundSelected,
  bass,
  bassSelected,
  nature,
  natureSelected,
  rain,
  rainSelected,
  radio,
  radioSelected,
  metal,
  metalSelected,
  psycho,
  psychoSelected,
  jazz1,
  jazz1Selected,
  hipHop1,
  hipHop1Selected,
  hipHop2,
  hipHop2Selected,
  middleEast,
  middleEastSelected,
  bangla,
  banglaSelected
} from '../util/svgs'

export default class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)

    // The files that we'll switch between, order is important - match the buttons
    this.sources = [NatureAudio,RainAudio,RadioAudio,GlitchAudio,BassAudio,MetalAudio,PsychoAudio ,DrumsAudio,Jazz1Audio,BanglaAudio,HipHop1Audio,HipHop2Audio,MiddleEastAudio,ClassicAudio]

    this.state = {
      currentSrcIndex: 3,
      playing: false,
      sound: 'off'
    }

    this.handleSwap = this.handleSwap.bind(this)
    this.handleStop = this.handleStop.bind(this)
  }

  componentDidMount() {
    filterButtons()
  }

  componentDidUpdate(prevProps, prevState) {
    if (store.getState().video.muted && this.state.playing && prevState.playing ) {
        this.setState({
          playing: false,
        })
    }

    if (!store.getState().video.muted && !this.state.playing && !prevState.playing && this.state.sound === 'on') {
      this.setState({
        playing: true,
      })
  }
    
  }

  handleSwap(param) {
    const nextIndex = param
    this.setState({ currentSrcIndex: nextIndex, playing: true, sound: 'on' })
  }

  handleStop() {
    this.setState({
      playing: false,
      sound: 'off'
    })
  }

  render() {
    return (
      <div className="flex">
        <div className="hidden">
          <ReactHowler
            playing={this.state.playing}
            src={this.sources[this.state.currentSrcIndex]}
            volume={0.4}
          />
        </div>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons active"
          type="button"
          onClick={this.handleStop}
        >
          <FilterButton normal={noIcon} selected={noIconSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(0)}
        >
          <FilterButton normal={nature} selected={natureSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(1)}
        >
          <FilterButton normal={rain} selected={rainSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(2)}
        >
          <FilterButton normal={radio} selected={radioSelected} />
        </button>

        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(3)}
        >
          <FilterButton normal={glitchSound} selected={glitchSoundSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(4)}
        >
          <FilterButton normal={bass} selected={bassSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(5)}
        >
          <FilterButton normal={metal} selected={metalSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(6)}
        >
          <FilterButton normal={psycho} selected={psychoSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(7)}
        >
          <FilterButton normal={drums} selected={drumsSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(8)}
        >
          <FilterButton normal={jazz1} selected={jazz1Selected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(9)}
        >
          <FilterButton normal={bangla} selected={banglaSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(10)}
        >
          <FilterButton normal={hipHop1} selected={hipHop1Selected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(11)}
        >
          <FilterButton normal={hipHop2} selected={hipHop2Selected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(12)}
        >
          <FilterButton normal={middleEast} selected={middleEastSelected} />
        </button>
        <button
          className="bg-none border-0 focus:outline-none w-13 filter-buttons"
          type="button"
          onClick={() => this.handleSwap(13)}
        >
          <FilterButton normal={classic} selected={classicSelected} />
        </button>
        
      </div>
    )
  }
}
