import React, { useEffect } from 'react'
import { filterBtn } from '../util'

const Button = ({ name }) => {
  useEffect(() => {
    filterBtn()
  }, [])

  return (
    <span
      className="border-2 border-white font-demi block text-white text-1.5xl px-6 py-1 transition uppercase sm-text-xl"
    >
      {name}
    </span>
  )
}

export default Button
